<template>
  <div @click="close">
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <h2>Case {{ getCase.FileNumber }}</h2>
    <hr>
    <b-row>
      <b-col md="9">
        <h2>{{ JournalEntryID ? "Edit Journal Entry" : "Add Journal Entry" }}</h2>

        <div class="white-bg-container">
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Internal Number"
                label-for="internalNumber"
              >
                <b-form-input
                  id="internalNumber"
                  v-model="getCase.FileNumber"
                  placeholder=""
                  name="internalNumber"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Subject"
                label-for="subject"
              >
                <b-form-input
                  id="subject"
                  v-model="Subject"
                  placeholder=""
                  name="subject"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Visible To"
                label-for="manager"
              >
                <b-row>
                  <b-col>
                    <b-form-checkbox
                      :id="'manager'"
                      v-model="User"
                      name="manager"
                      :disabled="true"
                    >
                      <p class="font-small-3">
                        User
                      </p>
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      :id="'investigator'"
                      v-model="Investigator"
                      name="investigator"
                      :disabled="readOnly"
                    >
                      <p class="font-small-3">
                        Investigator
                      </p>
                    </b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      :id="'client'"
                      v-model="Client"
                      name="client"
                      :disabled="readOnly"
                    >
                      <p class="font-small-3">
                        Client
                      </p>
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="5"
              class="pt-1 pb-1"
            >
              <b-form-group
                label="Journal Entry Category"
                label-for="journalEntryCategory"
              >
                <v-select
                  id="journalEntryCategory"
                  v-model="JournalEntryCategory"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="journalEntryCategoryOption"
                  placeholder=""
                  name="journalEntryCategory"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="JournalEntryCategory && (JournalEntryCategory.value === 'Daily Update' || JournalEntryCategory.value === 'Desktop Update')"
              md="6"
              class="pt-1 pb-1"
            >
              <b-form-group
                label="Select Case Action to Include"
                label-for="actionsToInclude"
              >
                <v-select
                  id="attachment"
                  v-model="ActionsToInclude"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="ActionsToIncludeOption"
                  multiple
                  placeholder=""
                  name="actionsToInclude"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Additional Info"
                label-for="additionalinfo"
              >
                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpanded}"
                >
                  <textarea
                    v-model="Title"
                    class="expandable-textarea"
                    :class="[isExpanded ? 'not-expanded' : 'expanded']"
                    contentEditable
                    :readonly="readOnly"
                  />
                  <div @click="isExpanded = !isExpanded">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpanded ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="JournalEntryCategory && (((JournalEntryCategory.value === 'Daily Update' ||
              JournalEntryCategory.value === 'Desktop Update') && actionIds && actionIds.length) ||
              JournalEntryCategory.value === 'Final Report & Invoice' || JournalEntryCategory.value === 'E-Mail' || JournalEntryCategory.value === 'Desktop E-Mail' ||
              JournalEntryCategory.value === 'Internal E-Mail' || JournalEntryCategory.value === 'Journal Collections')"
          >
            <b-col>
              <div class="white-bg-container">
                <b-row>
                  <b-col>
                    <b-form-group
                      label="FROM:"
                      label-for="from"
                      label-cols-sm="1.5"
                    >
                      <b-form-input
                        id="from"
                        class="ml-1"
                        :class="errFrom ? 'err-input' : ''"
                        v-model="From"
                        placeholder=""
                        name="from"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="TO:"
                      label-for="to"
                      label-cols-sm="1.5"
                    >
                      <b-form-input
                        id="to"
                        class="ml-1"
                        :class="errTo ? 'err-input' : ''"
                        v-model="To"
                        placeholder=""
                        name="to"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                    <div class="mb-1 ml-1">
                      <b-row>
                        <b-col class="col-sm-1"></b-col>
                        <b-col>
                          <ul name="text" class="user-list-wrap">
                            <li
                              v-for="user in toUserOptionList"
                              :key="user.title"
                              class="user-list"
                              @click="selectedUser(user.title, 'To')"
                            >{{ user.title }}</li>
                          </ul>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="CC:"
                      label-for="cc"
                      label-cols-sm="1.5"
                    >
                      <b-form-input
                        id="cc"
                        class="ml-1"
                        :class="errCc ? 'err-input' : ''"
                        v-model="Cc"
                        placeholder=""
                        name="cc"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                    <div class="mb-1 ml-1">
                      <b-row>
                        <b-col class="col-sm-1"></b-col>
                        <b-col>
                          <ul name="text" class="user-list-wrap">
                            <li
                              v-for="user in ccUserOptionList"
                              :key="user.title"
                              class="user-list"
                              @click="selectedUser(user.title, 'Cc')"
                            >{{ user.title }}</li>
                          </ul>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="BCC:"
                      label-for="bcc"
                      label-cols-sm="1.5"
                    >
                      <b-form-input
                        id="bcc"
                        class="ml-1"
                        :class="errBcc ? 'err-input' : ''"
                        v-model="Bcc"
                        placeholder=""
                        name="bcc"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                    <div class="mb-1 ml-1">
                      <b-row>
                        <b-col class="col-sm-1"></b-col>
                        <b-col>
                          <ul name="text" class="user-list-wrap">
                            <li
                              v-for="user in bccUserOptionList"
                              :key="user.title"
                              class="user-list"
                              @click="selectedUser(user.title, 'Bcc')"
                            >{{ user.title }}</li>
                          </ul>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="PRIORITY:"
                      label-for="importance"
                      label-cols-sm="1.5"
                    >
                      <v-select
                        id="importance"
                        class="ml-1"
                        v-model="Importance"
                        label="title"
                        :options="importanceOptions"
                        placeholder=""
                        name="importance"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="SUBJECT:"
                      label-for="subject"
                      label-cols-sm="1.5"
                    >
                      <b-form-input
                        id="subject"
                        class="ml-1"
                        v-model="SubjectEmail"
                        placeholder=""
                        name="subject"
                        :readonly="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="BODY:"
                      label-for="body"
                      label-cols-sm="1.5"
                    >
                      <vue-editor
                        id="editor"
                        v-model="Body"
                        class="pl-1 font-weight-normal"
                        :editor-toolbar="customToolbar"
                        :disabled="readOnly"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" style="max-width: 100%">
              <b-form-group
                label="Attachment"
                label-for="attachment"
              >
                <v-select
                  id="attachment"
                  v-model="Attachment"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="attachmentOption"
                  placeholder=""
                  multiple
                  name="attachment"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Media.length && JournalEntryCategory && (JournalEntryCategory.value === 'Daily Update' || JournalEntryCategory.value === 'Desktop Update')">
            <b-col>
              <b-form-group
                label="Select Media"
                label-for="selectMedia"
              >
                <div
                  id="selectMedia"
                  class="collapse-block"
                >
                  <div class="expandable-textarea">
                    <b-row>
                      <b-col
                        v-for="item in Media"
                        :key="item.FileID"
                        md="4"
                      >
                        <div
                          class="video-container"
                        >
                          <div class="center">
                            <input
                              type="checkbox"
                              :disabled="readOnly"
                              class="cursor-pointer mr-1 disabledCheckbox"
                              :checked="ThumbnailIDs.includes(item.FileID)"
                              @change="selectedMedia(item)"
                            >
                            <img
                              class="video-content"
                              :src="item.URL"
                              alt=""
                            >
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-if="!readOnly"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="submitForm()"
        >
          {{ JournalEntryCategory && (((JournalEntryCategory.value === 'Daily Update' ||
            JournalEntryCategory.value === 'Desktop Update') && actionIds && actionIds.length) ||
            JournalEntryCategory.value === 'Final Report & Invoice' || JournalEntryCategory.value === 'E-Mail' || JournalEntryCategory.value === 'Desktop E-Mail' ||
            JournalEntryCategory.value === 'Internal E-Mail' || JournalEntryCategory.value === 'Journal Collections') ? "Save & Send" : "Save" }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import { VueEditor } from "vue2-editor";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      JournalEntryID: "",

      User: true,
      Investigator: "",
      Client: "",
      Attachment: [],
      ActionsToInclude: [],
      actionIds: [],
      Title: "",
      JournalEntryCategory: "",
      Subject: "",
      InternalNumber: "",
      Media: [],
      checkedMedia: [],
      checkedMediaSize: 0,
      checkedAttachmentSize: 0,
      AttachmentIDs: [],
      ThumbnailIDs: [],
      ActionsIDs: [],
      userOptionList: [],
      toUserOptionList: [],
      ccUserOptionList: [],
      bccUserOptionList: [],
      templateData: null,
      editEntry: false,
      searchTerm: "",

      errFrom: false,
      errTo: false,
      errCc: false,
      errBcc: false,

      From: "",
      To: "",
      Cc: "",
      Bcc: "",
      Importance: {
        title: "Normal",
        value: 0,
      },
      SubjectEmail: "",
      Body: "",

      htmlForEditor: "",
      customToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
       ["blockquote", "code-block"],
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }],
        [{
          list: "ordered"
        }, {
          list: "bullet"
        }], [{
          indent: "-1"
        }, {
          indent: "+1"
        }],
        [{
          color: []
        }, {
          background: []
        }],
        ["link"]
      ],

      journalEntryCategoryOption: [],
      ActionsToIncludeOption: [],
      attachmentOption: [],
      attachmentOptionAll: [],
      PermissionMask: "",

      importanceOptions: [
        {
          title: "High",
          value: 2,
        },
        {
          title: "Normal",
          value: 0,
        },
        {
          title: "Low",
          value: 1,
        },
      ],

      readOnly: false,
      isExpanded: false,
      isExpandedMedia: false,
      isExpandedBody: true,
    }
  },
  computed: {
    ...mapGetters({
      getCase: "cases/getCase",
      getUserType: "auth/getUserType",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.getCase.FileNumber,
        },
      ]
    },
  },
  watch: {
    Body(val) {},
    User(val) {
      this.changeData("User;", val)
    },
    Investigator(val) {
      this.changeData("Investigator;", val)
    },
    Client(val) {
      this.changeData("Client;", val)
    },
    JournalEntryCategory(val) {
      if (!this.JournalEntryID && val && (val.value === 'E-Mail' || val.value === 'Desktop E-Mail' || val.value === 'Internal E-Mail' ||
          val.value === 'Final Report & Invoice' || val.value === 'Journal Collections')) {
        this.getTemplateData(val.title);
      }
    },
    ActionsToInclude(val) {
      this.actionIds = val.map(i => {
        return i.id
      })
    },
    actionIds(val) {
      if (val && val.length) {
        this.openEmail()
        this.loadMedia()
      } else {
        this.templateData = null
        this.Media = [];
      }
    },
    Attachment(val) {
      this.attachmentOption = this.attachmentOptionAll.filter(i => !val.includes(i))
    },
    From() {
      this.errFrom = false;
    },
    To(val, oldVal) {
      this.errTo = false;
      this.toUserOptionList = [];
      this.fieldWatcher(val, oldVal, "To")
    },
    Cc(val, oldVal) {
      this.errCc = false;
      this.ccUserOptionList = [];
      this.fieldWatcher(val, oldVal, "Cc")
    },
    Bcc(val, oldVal) {
      this.errBcc = false;
      this.bccUserOptionList = [];
      this.fieldWatcher(val, oldVal, "Bcc")
    },
  },
  mounted() {
    this.editEntry = this.$route.meta.edit;
    this.readOnly = this.$route.meta.view;
    this.changeData("User;", true)
  },
  async created() {
    try {
      this.loadUserPage();
      this.fillData();
      const response = await apiService.get('journal/categories').then(res => res.data).catch(error => error)
      this.journalEntryCategoryOption = response.categories.map(i => ({title: i.value, value: i.value, id: i.id}));
      if (this.getUserType === "Investigator") {
          this.journalEntryCategoryOption = this.journalEntryCategoryOption.filter(i => i.title === "Journal Entry")
      }
      if (this.getUserType === "Client") {
          this.journalEntryCategoryOption = this.journalEntryCategoryOption.filter(i => i.title === "Message from Client")
      }
      this.JournalEntryID = this.$route.params.journalEntryID;
      this.readOnly = this.$route.meta.view;
      this.editEntry = this.$route.meta.edit;
      if (this.JournalEntryID) {
        apiService
            .get("journal/entry/" + this.JournalEntryID + "/detail")
            .then(res => {
              this.Title = res.data.Title;
              this.fillPermissionMask(res.data.PermissionMask);
              this.JournalEntryCategory = this.journalEntryCategoryOption.filter(i => i.id === res.data.UpdateCategoryListItemID)[0];
              this.AttachmentIDs = res.data.AttachmentIDs;
              this.ThumbnailIDs = res.data.ThumbnailIDs;
              this.checkedMedia = res.data.ThumbnailIDs;
              this.ActionsIDs = res.data.ActionsIDs;
              if (res.data.Email) {
                this.templateData = res.data.Email;
              }
              apiService
                  .get("case/" + res.data.CaseID + "/detail")
                  .then((response) => {
                    this.setCase(response.data);
                    this.fillData();
                    this.fillDataEmail();
                  })
            })
            .catch(error => {
              console.log("error ", error)
            })
      }
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    ...mapMutations({
      setCase: "cases/setCase",
    }),
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return val
    },
    fillData() {
      this.Subject = this.getCase.Branch.Client.Subject.LastName ? (this.getCase.Branch.Client.Subject.LastName + ', ' + this.getCase.Branch.Client.Subject.FirstName) : '';
      this.attachmentOption = this.getCase.Files.map(i => ({title: i.FileName, value:  i.FileName, id: i.FileID, url: i.URL, size: i.FileSize}));
      this.attachmentOptionAll = this.attachmentOption;
      if (this.AttachmentIDs && this.AttachmentIDs.length) {
        this.Attachment = [];
        this.AttachmentIDs.map(item => {
          const attachment = this.attachmentOption.filter(i => i.id === item)[0];
          return this.Attachment.push(attachment)
        })
      }
      this.loadActionLogs();
    },
    getTemplateData(name) {
      let templateID = ""
      switch (name) {
        case "E-Mail":
          templateID = "journal-email-template";
          break
        case "Desktop E-Mail":
          templateID = "desktop-email-template";
          break
        case "Internal E-Mail":
          templateID = "internal-journal-email-template";
          break
        case "Final Report & Invoice":
          templateID = "journal-final-report-email-template";
          break
        case "Journal Collections":
          templateID = "journal-collections-email-template";
          break
      }
      apiService
          .get("template/" + templateID + "/parse-with-case/" + this.getCase.CaseID)
          .then(res => {
            if (!this.readOnly) {
              this.templateData = res.data;
            }
            this.fillDataEmail()
          })
    },
    loadActionLogs() {
      apiService
          .get("actions/short-info/case/" + this.getCase.CaseID)
          .then((res) => {
            this.ActionsToIncludeOption = res.data.map(i => ({title: i.ActionFileNumber + " - " + i.ActionTypeName + " : " + this.dateFormat(i.ScheduledDate), value:  i.ActionFileNumber, id: i.ActionID}));
            if (this.ActionsIDs && this.ActionsIDs.length) {
              this.ActionsToInclude = [];
              this.ActionsIDs.map(item => {
                const action = this.ActionsToIncludeOption.filter(i => i.id === item)[0];
                this.ActionsToInclude.push(action);
              })
            }
          })
    },
    loadUserPage() {
      apiService
          .get("users/User,Investigator?max=10000")
          .then((response) => {
            this.userOptionList = response.data.Users.map(item => {
              return {title: item.FirstName + " " + item.LastName + " <" + item.EMail + ">", value: item.EMail}
            })
          });
    },

    userSwitcher(val) {
      switch (val) {
        case "To":
          this.toUserOptionList = this.userOptionList.filter(i => i.title.indexOf(this.searchTerm) !== -1);
          break
        case "Cc":
          this.ccUserOptionList = this.userOptionList.filter(i => i.title.indexOf(this.searchTerm) !== -1);
          break
        case "Bcc":
          this.bccUserOptionList = this.userOptionList.filter(i => i.title.indexOf(this.searchTerm) !== -1);
          break
      }
    },

    selectedUser(user, field) {
      this.toUserOptionList = [];
      this.ccUserOptionList = [];
      this.bccUserOptionList = [];

      let itemArr = this[field].split(";")
      itemArr = itemArr.map(item => {
        if (item.trim() === this.searchTerm) {
          item = ( " " + user + " ")
        }
        return item;
      })
      this[field] = itemArr.join(";")
      this.searchTerm = "";
    },
    fieldWatcher(val, oldVal, field) {
      if (!val || !oldVal) return
      const usersArr = val.split(";");
      const oldUsersArr = oldVal.split(";");
      if (usersArr.length === oldUsersArr.length) {
        usersArr.map((item, index) => {
          if (item !== oldUsersArr[index]) {
            this.searchTerm = item.trim();
          }
        })
      }
      if (this.searchTerm && this.searchTerm.length && this.searchTerm.length >= 3) {
        this.userSwitcher(field);
      }
    },
    close() {
      if (this.toUserOptionList.length) this.toUserOptionList = [];
      if (this.ccUserOptionList.length) this.ccUserOptionList = [];
      if (this.bccUserOptionList.length) this.bccUserOptionList = [];
    },
    openEmail() {
      let actionIds = this.actionIds.map(item => {
        return "actionIds=" + item;
      })
      const template = this.JournalEntryCategory.value === "Daily Update" ? "journal-daily-update-email-template" : "journal-desktop-update-email-template"
      actionIds = actionIds.join("&");
      apiService
          .get("template/" + template + "/parse-with-case/" + this.getCase.CaseID + "?" + actionIds)
          .then(res => {
            if (!this.readOnly) {
              this.templateData = res.data;
            }
            this.fillDataEmail()
          })
    },
    loadMedia() {
      const postData = {
        ActionIDs: this.actionIds,
      }
      apiService
          .post("action/activity/media", postData)
          .then(res => {
            this.Media = res.data
          })
    },
    fillDataEmail() {
      let templateData = Object.assign({}, this.templateData);
      for (const key in this.templateData) {
        if (key !== "Priority")
        templateData[key] = typeof this.templateData[key] === "string" && this.templateData[key].replace("{Category}", this.JournalEntryCategory.title)
      }
      for (const key in templateData) {
        if (key !== "Priority")
        templateData[key] = typeof this.templateData[key] === "string" && templateData[key].replace("{ActivityTime}", new Date().toLocaleDateString())
      }
      this.From = templateData.From || "";
      this.To = templateData.To || "";
      this.Cc = templateData.Cc || templateData.CC || "";
      this.Bcc = templateData.Bcc || templateData.BCC || "";
      this.Importance = templateData.Priority ? this.importanceOptions.filter(i => i.value === templateData.Priority)[0] : this.Importance;
      this.SubjectEmail = templateData.Subject || "";
      if (templateData.Body) {
        this.Body = this.htmlParser(templateData.Body)
      } else if (templateData.BodySection1) {
        this.Body = this.htmlParser(templateData.BodySection1)
      }
    },
    htmlParser(val) {
      val = val.replaceAll("<p><br></p>", "\n");
      val = val.replaceAll("</p><br><br>", "\n\n");
      val = val.replaceAll("<br>", "\n");
      val = val.replaceAll(/\&nbsp;/g, " ")
      return val
    },
    parserToHtml(val) {
      val = val.replaceAll(`class="ql-align-center"`, `style="text-align: center"`);
      val = val.replaceAll(`class="ql-align-right"`, `style="text-align: right"`);
      val = val.replaceAll(`class="ql-align-justify"`, `style="text-align: justify"`);

      val = val.replaceAll("\n", "<br>");
      val = val.replaceAll("<p>", "");

      let result = val.split("<p ");
      result = result.map((item, index) => {
        if (index) return item.replace("</p>", "</ph> ");
        return item;
      })
      val = result.join("<p ")

      val = val.replaceAll("<br></p>", "<br>");
      val = val.replaceAll("</p>", "<br>");

      val = val.replaceAll("</ph>", "</p>")
      return val

    },
    fillPermissionMask(val) {
      const permArr = val.split(";")
      permArr.map(item => {
        this[item] = true;
      });
    },
    selectedMedia(val) {
      if (this.checkedMedia.indexOf(val.FileID) === -1) {
        this.checkedMedia.push(val.FileID)
      } else {
        this.checkedMedia.splice(this.checkedMedia.indexOf(val.FileID), 1)
      }
    },
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    resetForm() {
      this.$router.push("/cases/" + this.getCase.CaseID + "/detail")
    },
    returnIds(arr) {
      return  arr.map(item => item.id) || []
    },

    objToArr(obj) {
      return Object.keys(obj).map((key) => obj[key]);
    },

    submitForm() {
      let validEmail = true;
      const encoder = new TextEncoder();
      const email = {
        "From": this.From,
        "To": this.To,
        "CC": this.Cc,
        "BCC": this.Bcc,
        "Subject": this.SubjectEmail,
        "BinaryBody": this.objToArr(encoder.encode(this.parserToHtml(this.Body))),
        "Priority": this.Importance.value,
      }
      const actionIds = this.actionIds;
      let postData = {
        "CaseID": this.getCase.CaseID,
        "Title": this.Title,
        "PermissionMask": this.PermissionMask,
        "UpdateCategoryListItemID": this.JournalEntryCategory.id || null,
        "FileIDs": this.returnIds(this.Attachment),
        "sendEmail": false,
      }
      if (this.JournalEntryCategory && (this.JournalEntryCategory.value === 'Daily Update' || this.JournalEntryCategory.value === 'Desktop Update' ||
          this.JournalEntryCategory.value === 'Journal Collections' || this.JournalEntryCategory.value === 'Final Report & Invoice' ||
          this.JournalEntryCategory.value === 'E-Mail' || this.JournalEntryCategory.value === 'Desktop E-Mail' || this.JournalEntryCategory.value === 'Internal E-Mail')) {
        postData = {...postData, email};
        postData.sendEmail = true;

        this.errFrom = !this.validateEmail(email.From);
        if (email.To) {
          const toArr = email.To.split(";");
          toArr.map(item => {
            if (!this.errTo) this.errTo = !this.validateEmail(item)
          })
        } else {
          this.errTo = true
        }
        if (email.CC) {
          const ccArr = email.CC.split(";");
          ccArr.map(item => {
            if (!this.errCc) this.errCc = !this.validateEmail(item)
          })
        }
        if (email.BCC) {
          const bccArr = email.BCC.split(";");
          bccArr.map(item => {
            if (!this.errBcc) this.errBcc = !this.validateEmail(item)
          })
        }
        if (this.errFrom || this.errTo || this.errCc || this.errBcc) {
          let errMsg = this.errFrom ? "FROM" : "";
          errMsg = this.errTo ? errMsg ? errMsg + ", TO" : "TO" : errMsg + "";
          errMsg = this.errCc ? errMsg ? errMsg + ", CC" : "CC" : errMsg + "";
          errMsg = this.errBcc ? errMsg ? errMsg + ", BCC" : "BCC" : errMsg + "";
          this.showToast('warning', 'top-center', 4000, 'Field ' + errMsg + ' must contain the correct email address!');
          return
        }
      }
      if (this.JournalEntryCategory && (this.JournalEntryCategory.value === 'Daily Update' || this.JournalEntryCategory.value === 'Desktop Update')) {
        postData = {...postData, actionIds}
      }
      if (this.checkedMedia.length) {
        postData = {...postData, thumbnailIDs: this.checkedMedia}
      }
      const endPoint = "journal/entries/create";

      if (validEmail) {
        apiService.post(endPoint, postData)
            .then(res => {
              if (res) {
                this.showToast('success', 'top-center', 4000, 'Journal Entry has been added!');
                this.$router.push("/cases/" + this.getCase.CaseID + "/detail")
              }
            })
      }
    },

    validateEmail(emailAdress) {
      let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      return !!emailAdress.match(regexEmail);
    },

    changeData(text, inc) {
      if (inc) {
        if (this.PermissionMask.indexOf(text) === -1) {
          this.PermissionMask = this.PermissionMask + text
        }
      } else {
        if (this.PermissionMask.indexOf(text) !== -1) {
          this.PermissionMask = this.PermissionMask.replace(text, "")
        }
      }
    },
  }
}
</script>

<style scoped>
.video-container {
  width: 15rem;
  height: 15rem;
  margin: 1rem;
}
.video-content {
  width: 12rem;
  height: 12rem;
}
.user-list-wrap {
  position: absolute;
  z-index: 1;
  left: -2rem;
  top: -1rem;
  max-height: 18rem;
  overflow: auto;
}
.dark-layout .user-list {
  background-color: #4d5061;
}
.user-list {
  cursor: pointer;
  background: aliceblue;
  list-style: none;
  padding: 0.5rem;
}
.err-input {
  border-color: red !important;
}
</style>